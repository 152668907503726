// "use client";
// import Script from "next/script";
// import React, { useEffect, useState, useRef } from "react";
// import { getPrefLangCookie } from '@/functions/langCookis'; // Adjust the import path as necessary

// const languages = [
//     { label: "English", value: "en", src: "https://flagcdn.com/h60/us.png" },
//     { label: "Spanish", value: "es", src: "https://flagcdn.com/h60/es.png" },
//     { label: "French", value: "fr", src: "https://flagcdn.com/h60/fr.png" },
//     { label: "Arabic", value: "ar", src: "https://flagcdn.com/h60/sa.png" }, // Added Arabic language
//     { label: "Russian", value: "ru", src: "https://flagcdn.com/h60/ru.png" }, // Added Russian language
//     // Add more languages here
// ];

// export default function GoogleTranslate() {
//   const [langCookie, setLangCookie] = useState('');
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const dropdownRef = useRef(null);

//   // Load the language preference from cookies
//   useEffect(() => {
//     const fetchLanguageCookie = async () => {
//       const cookieValue = await getPrefLangCookie();
//       setLangCookie(cookieValue);
//     };
//     fetchLanguageCookie();
//   }, []);

//   // Define the initialization function for Google Translate with a callback
//   useEffect(() => {
//     window.googleTranslateElementInit = () => {
//       if (window.google && window.google.translate && typeof window.google.translate.TranslateElement === 'function') {
//         new window.google.translate.TranslateElement(
//           {
//             pageLanguage: "auto",
//             includedLanguages: languages.map(lang => lang.value).join(","),
//           },
//           "google_translate_element"
//         );
//       }
//     };
//   }, []);

//   // Initialize Google Translate once the script is loaded and language cookie is set
//   useEffect(() => {
//     const setLanguageInGoogleTranslate = () => {
//       const translateElement = document.querySelector(".goog-te-combo");
//       if (translateElement) {
//         translateElement.value = langCookie.split("/")[2]; // Set initial value from langCookie
//         translateElement.dispatchEvent(new Event("change")); // Trigger the translation
//       }
//     };

//     if (window.google && window.google.translate) {
//       window.googleTranslateElementInit(); // Call the initialization
//       setLanguageInGoogleTranslate(); // Set the selected language
//     }
//   }, [langCookie]);

//   // Handle language selection
//   const handleChange = (value) => {
//     const lang = `/en/${value}`;
//     setLangCookie(lang);
//     document.cookie = `googtrans=${lang}; path=/`; // Set the cookie
//     window.location.reload(); // Reloads the page to apply the language change
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen((prev) => !prev);
//   };

//   const switchLanguage = (value) => () => {
//     handleChange(value);
//     setIsDropdownOpen(false); // Close dropdown after selection
//   };

//   const currentLanguage = langCookie.split("/")[2] || 'en'; // Default to 'en' if value is empty

//   return (
//     <div>
//       <div id="google_translate_element" style={{ display: "none" }}></div>

//       <div className="relative inline-block text-sm" ref={dropdownRef}>
//         <button
//           className="text-gray-200 px-4 py-2 rounded-md focus:outline-none"
//           onClick={toggleDropdown}
//         >
//           <span className="notranslate">
//             {languages.find(lang => lang.value === currentLanguage)?.label || 'Select Language'}
//           </span>
//           <svg
//             className={`w-4 h-4 inline ml-2 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//           >
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
//           </svg>
//         </button>

//         {isDropdownOpen && (
//           <div className="absolute right-0 mt-2 w-28 bg-zinc-800 rounded-md shadow-lg z-10">
//             {languages.map((lang) => (
//               <a
//                 key={lang.value}
//                 onClick={switchLanguage(lang.value)}
//                 className={`block px-4 py-2 text-gray-200 hover:bg-zinc-500 cursor-pointer notranslate ${
//                   currentLanguage === lang.value ? 'font-bold text-orange-300' : ''
//                 }`}
//               >
//                 {lang.label}
//               </a>
//             ))}
//           </div>
//         )}
//       </div>

//       <Script
//         src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
//         strategy="afterInteractive"
//         onLoad={() => {
//           if (window.googleTranslateElementInit) {
//             window.googleTranslateElementInit();
//           }
//         }}
//       />
//     </div>
//   );
// }


"use client";

import Script from "next/script";
import React, { useEffect, useState, useRef } from "react";

const languages = [
  { label: "English", value: "en", src: "https://flagcdn.com/h60/us.png" },
  { label: "Spanish", value: "es", src: "https://flagcdn.com/h60/es.png" },
  { label: "French", value: "fr", src: "https://flagcdn.com/h60/fr.png" },
  { label: "Arabic", value: "ar", src: "https://flagcdn.com/h60/sa.png" },
  { label: "Russian", value: "ru", src: "https://flagcdn.com/h60/ru.png" },
];

export default function GoogleTranslate() {
  const [langCookie, setLangCookie] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const dropdownRef = useRef(null);
  const initializeAttemptRef = useRef(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchLanguageCookie = async () => {
      try {
        const cookieValue = document.cookie
          .split("; ")
          .find((row) => row.startsWith("googtrans="))
          ?.split("=")[1];
        setLangCookie(cookieValue || "/auto/en");
      } catch {
        setLangCookie("/auto/en");
      }
    };
    fetchLanguageCookie();
  }, []);

  useEffect(() => {
    if (!isScriptLoaded) return;

    const initTranslate = () => {
      if (initializeAttemptRef.current > 20) return;
      if (window.google?.translate?.TranslateElement) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "auto",
            includedLanguages: languages.map((lang) => lang.value).join(","),
            autoDisplay: false,
          },
          "google_translate_element"
        );
      } else {
        initializeAttemptRef.current++;
        setTimeout(initTranslate, 200);
      }
    };

    window.googleTranslateElementInit = initTranslate;
    initTranslate();
  }, [isScriptLoaded]);

  const handleLanguageChange = (value) => {
    try {
      const now = new Date();
      now.setFullYear(now.getFullYear() + 1);
      const lang = `/auto/${value}`;
      document.cookie = `googtrans=${lang};expires=${now.toUTCString()};path=/`;

      setLangCookie(lang);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (err) {
      console.error("Error changing language:", err);
    }
  };

  const currentLanguage = langCookie.split("/")[2] || "en";

  return (
    <div>
      <div id="google_translate_element" className="hidden"></div>

      <div className="relative inline-block text-sm" ref={dropdownRef}>
        <button
          className="text-gray-200 px-4 py-2 rounded-md focus:outline-none flex items-center"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          aria-expanded={isDropdownOpen}
        >
          <span>{languages.find((lang) => lang.value === currentLanguage)?.label || "Select Language"}</span>
          <svg
            className={`w-4 h-4 ml-2 transition-transform ${isDropdownOpen ? "rotate-180" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-28 bg-zinc-800 rounded-md shadow-lg z-50">
            {languages.map((lang) => (
              <button
                key={lang.value}
                onClick={() => {
                  handleLanguageChange(lang.value);
                  setIsDropdownOpen(false);
                }}
                className={`w-full text-left px-4 py-2 text-gray-200 hover:bg-zinc-500 cursor-pointer ${
                  currentLanguage === lang.value ? "font-bold text-orange-300" : ""
                }`}
              >
                {lang.label}
              </button>
            ))}
          </div>
        )}
      </div>

      <Script
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        strategy="lazyOnload"
        onLoad={() => setIsScriptLoaded(true)}
      />
    </div>
  );
}
